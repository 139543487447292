
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CommonApi } from "@/common/api/common";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Util, getPageAPIs } from "@/common/util";
import gallery from "@/components/gallery.vue";
import "@wangeditor/editor/dist/css/style.css";

import { DomEditor } from "@wangeditor/editor";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";

import MolisWebsiteDynamicAPI from "@/common/api/operationManage/commonWebsiteDynamicManage";

import {
  projectWebSitType2Ch,
  getCommonList,
  shareConfirmOptions,
} from "@/common/config";

export const pageApiList = getPageAPIs(MolisWebsiteDynamicAPI);

type InsertFnType = (url: string, alt: string, href: string) => void;

const defaultForm = {
  type: 0, // 类型 1麦力士 2沐尘 3熊猫球社(传0或不传为所有)
};

// 状态:0下架,1上架
const dynamicStatus2ch: string[] = ["下架", "上架"];

const defaultForms: any = {
  type: 1, // 默认是麦力士  类型 1麦力士 2沐尘 3熊猫球社
  title: "", // 标题
  images: [], // 封面图
  content: "", // 富文本

  abstract: "", // 摘要

  publish_time: "", // 发布时间
  sort: "", // 排序
  status: 1, // 是否上架 状态:0下架,1上架
  publish_name: "", // 发布人
};

@Component({
  components: {
    gallery,
    WangToolbar: Toolbar,
    WangEditor: Editor,
  },
  filters: {
    showDynamicStatus(status: number) {
      return dynamicStatus2ch[status] || "未知";
    },
    showWebsitTypeName(type: number) {
      return projectWebSitType2Ch[type] || "未知";
    },
  },
})
export default class MerchantsAudit extends Vue {
  private molisWebsiteDynamicApi: any;
  private title: string = "";
  private is_show: boolean = false;
  private commonApi = new CommonApi();
  private hideUpload: boolean = false;
  private form: any = {};
  private forms: any = {};

  private deepLoopUtil: any;

  private websitProjectTypeList: any[] = [
    ...getCommonList(projectWebSitType2Ch, 1),
  ];

  constructor() {
    super();
    this.molisWebsiteDynamicApi = new MolisWebsiteDynamicAPI();

    this.deepLoopUtil = new DeepLoop();

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm, false),
    };

    this.wangEditorConfig = {
      placeholder: "请输入内容...",
      MENU_CONF: {
        uploadImage: {
          // 单个文件的最大体积限制，默认为 2M
          maxFileSize: 10 * 1024 * 1024, // 10M

          // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
          allowedFileTypes: ["image/*"],

          // 自定义上传
          customUpload: async (file: File, insertFn: InsertFnType) => {
            // TS 语法
            // async customUpload(file, insertFn) {                   // JS 语法
            // file 即选中的文件
            // 自己实现上传，并得到图片 url alt href
            // 最后插入图片
            this.commonApi.getOss(
              file,
              (res: any) => {
                const {
                  // id,
                  url,
                  alt,
                  href,
                } = res.data.data.item;

                insertFn(url, alt, href);
              },
              () => {
                this.$message.error("上传图片失败");
              }
            );
          },
        },
      },
    };
  }

  private daynamicDialogMode: string = "new-add";
  private handleWakeDynamicDialog(mode: string): void {
    this.daynamicDialogMode = "new-add";
    this.title = "新增";

    this.initDynamicDialogForms();

    this.is_show = true;
  }

  private async handleWakeEditDynamicDialog(row: any) {
    this.daynamicDialogMode = "edit";
    this.title = "编辑";

    await this.initDynamicDialogForms(row);

    this.is_show = true;
  }

  /** 富文本 */
  private wangEditorIns: any = null;

  private wangEditorForm: any = {
    compete_info: "",
  };

  private wangEditorMode: string = "default"; // or 'simple'

  private wangToolbarConfig: any = {
    // excludeKeys: ["insertLink", "group-video", "emotion", "insertImage", "insertTable", "fullScreen"]

    excludeKeys: [
      "group-video",
      "emotion",
      "insertImage",
      "insertTable",
      "fullScreen",
    ],
  };
  private wangEditorConfig: any = {};

  private get dOMWangEditor() {
    const refs: any = this.$refs || {};

    return refs.domWangEditor || {};
  }

  private onWangEditorCreated(editor: any) {
    this.wangEditorIns = Object.seal(editor);

    console.log(this.wangEditorIns, "this.wangEditorIns");

    function getToolbarConfig(editor: any) {
      const toolbar: any = DomEditor.getToolbar(editor);

      console.log(toolbar, "toolbar");

      const curToolbarConfig = toolbar.getConfig();

      console.log(curToolbarConfig.toolbarKeys, "curToolbarConfig.toolbarKeys");
    }

    setTimeout(() => {
      getToolbarConfig(this.wangEditorIns);
    }, 1000);
  }

  /** 富文本 */

  private async initDynamicDialogForms(dynamicItem: any = null) {
    const mode = this.daynamicDialogMode;

    const forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit") {
      const {
        id,
        type,
        title,
        image_id,
        image_url,
        summary: abstract,
        sort,
        status,
        publish_time,
        publish_name,
      } = dynamicItem || {};

      const { content } = await this.molisWebsiteDynamicApi
        .getMolisDynamicDetailInfo({
          id,
        })
        .then((res: any) => res.data || {});

      // console.log(dynamicInfo, 'dynamicInfo')

      Object.assign(forms, {
        id,
        type,
        title: title || "",
        content: content || "",
        abstract: abstract || "",
        publish_time: publish_time || "",
        publish_name: publish_name || "",
        sort,
        status,
      });

      if (image_url && typeof image_id == "number") {
        forms.images[0] = {
          fileId: image_id,
          url: image_url,
        };
      }
    }

    console.log(forms, "forms");

    this.forms = forms;
  }

  // private getOss(file: File, cb: Function) {
  //   console.log(file);
  //   if (file.type == "image/jpeg" || file.type == "image/png") {
  //     this.commonApi.getOss(
  //         file,
  //         (res: any) => {
  //           cb(res);
  //         },
  //         () => {}
  //     );
  //   } else {
  //     this.commonApi.getOssvideo(
  //         file,
  //         (res: any) => {
  //           cb(res);
  //         },
  //         () => {}
  //     );
  //   }
  // }

  // private handlePictureCardPreview(file) {
  //   this.dialogImageUrl = file.url;
  //   this.dialogVisible = true;
  // }

  private delMolisDynamic(row: any) {
    this.$confirm(`确定删除该动态?`, "", {
      ...shareConfirmOptions,
    }).then(() => {
      this.molisWebsiteDynamicApi.delMolisDynamic(
        {
          id: row.id,
        },
        () => {
          this.is_show = false;
          Util.showToast("删除成功");
          this.search();
        }
      );
    });
  }

  private getMolisDynamicFormsParams() {
    const forms: any = this.forms;

    const mode = this.daynamicDialogMode;

    const { id, type, title, images, content, abstract, sort, status, publish_time, publish_name = '' } =
      forms || {};

    const params: any = {
      type,
      publish_name
    };

    if (mode == "edit") {
      Object.assign(params, {
        id,
      });
    }

    if (!title) {
      this.$message.error("标题不可为空");
      return false;
    }

    if (!(images[0] && typeof images[0].fileId == "number")) {
      this.$message.error("封面图不可为空");
      return false;
    }

    if (!content) {
      this.$message.error("内容不可为空");
      return false;
    }

    if (!abstract) {
      this.$message.error("摘要不可为空");
      return false;
    }

    if (!publish_time) {
      this.$message.error("发布日期不可为空");
      return false;
    }

    Object.assign(params, {
      title,
      image_id: images[0].fileId,
      content,
      summary: abstract,
      publish_time,
      sort,
      status,
    });

    return params;
  }

  private submit(): void {
    const mode = this.daynamicDialogMode;

    const params = this.getMolisDynamicFormsParams();

    if (typeof params !== "object") return;

    switch (mode) {
      case "new-add":
        this.molisWebsiteDynamicApi.addMolisDynamic(params, () => {
          this.is_show = false;
          Util.showToast("新增成功");
          this.search();
        });
        break;
      case "edit":
        this.molisWebsiteDynamicApi.editMolisDynamic(params, () => {
          this.is_show = false;
          Util.showToast("修改成功");
          this.search();
        });
        break;
    }
  }

  // 预览媒体文件
  private previewImages: any[] = [];
  private previewEffects: any[] = [];
  private handlePictureCardPreview(file: any, field: string, mode: string) {
    const forms = this.forms;

    if (field == "effects") {
      // file._player.startAnimation()
      const images = forms[field];

      this.previewEffects = [
        ...images.map((img: any) => {
          return {
            title: "",
            type: "svga",
            href: img.url,
          };
        }),
      ];

      return;
    }

    const images = mode == "image" ? forms[field] : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const urls = [
      ...images.map((img: any) => {
        return {
          title: "",
          type: "image/jpeg",
          href: img.url,
          thumbnail: img,
        };
      }),
      ...videos.map((video: any) => {
        return {
          title: "",
          type: "video/mp4",
          href: video.url,
          poster: `${video.url}?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto`,
        };
      }),
    ];

    this.previewImages = urls;
  }

  // 上传媒体文件
  private handleUploadUserImages(file: any, fileList: any[]) {
    this.handleUploadUserMedia(file, fileList, "images", "image");
  }

  // private handleUplodUserEffects(file: any, fileList: any[]) {
  //   this.handleUploadUserMedia(file, fileList, "effects", "image");
  // }

  private async handleUploadUserMedia(
    file: any,
    fileList: any[],
    field: string,
    mode: string
  ) {
    console.log(file, "file");

    function getVideoDuration(url: string): any {
      return new Promise((resolve, reject) => {
        var audio = new Audio(url);
        audio.muted = true;
        audio.play().then(() => audio.pause());
        // 元数据已加载

        const handleLoadedmetadata = function (e: any) {
          // let duration = audio.duration;
          console.log(e, "e audio");
          resolve({
            url,
            duration: audio.duration,
          });

          hanleClearListeners();
        };

        const hanleClearListeners = function () {
          audio.removeEventListener("loadedmetadata", handleLoadedmetadata);
          audio.onerror = null;
        };
        audio.addEventListener("loadedmetadata", handleLoadedmetadata);

        audio.onerror = function (err) {
          reject(err);
        };

        audio.muted = false;
      });
    }

    const forms: any = this.forms;

    const optionList = mode == "image" ? forms[field] : forms.videos;

    const sendMediaApi =
      mode == "image" ? this.commonApi.getOss : this.commonApi.getOssvideo;

    if (mode == "video" && !file.duration) {
      const { duration } = await getVideoDuration(file.url).catch(
        (err: any) => {
          this.$message("获取时长失败");
        }
      );

      file.duration = duration;
    }

    optionList.push(file);

    console.log(forms, "forms");

    this.$set(this.forms, `${field}`, optionList);

    this.changeFilePropByList(file, mode, field, { uploading: true });
    sendMediaApi.call(
      this.commonApi,
      file instanceof File ? file : file.raw,
      (res: any) => {
        console.log(res, "res 图片上传成功");

        const { data } = res;

        const { data: uploadFileObj } = data || {};

        const { item: uploadFile } = uploadFileObj || {};

        const { id, url } = uploadFile;

        // this.$set(this.forms, 'avatar_id', id)
        // this.$set(this.forms, 'avatar', url)
        const changeProps: any = {
          uploading: false,
          fileId: id,
          uploadUrl: url,
        };

        if (file.duration) {
          changeProps.duration = file.duration.toString();
        }

        this.changeFilePropByList(file, mode, field, changeProps);
      },
      () => {
        this.$message.error("图片上传失败");

        this.changeFilePropByList(file, mode, field, {
          uploading: false,
          failed: true,
        });
      }
    );
  }

  private changeFilePropByList(
    file: any,
    mode: string,
    field: string,
    changes: any = {}
  ) {
    const forms: any = this.forms;

    const setList: any[] = mode == "image" ? forms[field] : forms.videos;
    const existFileIndex = setList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existFileIndex > -1) {
      const originFile = setList[existFileIndex];

      if (Array.isArray(setList)) {
        setList.splice(existFileIndex, 1, Object.assign(originFile, changes));
      }

      this.$set(this.forms, `${field}`, setList);
    }
  }

  // 删除媒体文件
  private handleFileRemove(file: any, field: string, mode: string) {
    const forms = this.forms;

    const images = mode == "image" ? forms[field] : [];

    const videos = mode == "video" ? forms.videos : [];

    console.log(file, "file");

    const optionList = images.length > 0 ? images : videos;

    const existRemoveFileIndex = optionList.findIndex(
      (each_file: any) => each_file.uid == file.uid
    );

    if (existRemoveFileIndex > -1) {
      optionList.splice(existRemoveFileIndex, 1);

      this.$set(this.forms, `${field}`, optionList);
    }
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    this.search();
  }

  private getTable(): void {
    const form = this.form || {};
    this.molisWebsiteDynamicApi.getMolisDynamic(
      {
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
        ...form,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}
