import { BasicsClass } from '@/common/BasicsClass';
import { Util } from '@/common/util';

export default class MolisWebsiteDynamicAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getMolisDynamic', {
            url: '/admin/molisDynamic/list',
            name: 'list',
            label: '列表'
        }],
        ['getMolisDynamicDetailInfo', {
            url: '/admin/molisDynamic/info',
            name: 'detail',
            label: '动态详情'
        }],
        ['addMolisDynamic',  {
            url: '/admin/molisDynamic/add',
            name: 'add',
            label: '添加'
        }],
        ['editMolisDynamic',  {
            url: '/admin/molisDynamic/edit',
            name: 'edit',
            label: '编辑'
        }],
        ['delMolisDynamic',  {
            url: '/admin/molisDynamic/delete',
            name: 'delete',
            label: '删除'
        }],
    ])
    // 麦力士动态列表
    public getMolisDynamic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMolisDynamic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    // 麦力士动态详情
    public getMolisDynamicDetailInfo(params: any, successCb?: Function, failCb?: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('getMolisDynamicDetailInfo').url

        return new Promise((resolve, reject) => {
            this.BasicPost(url, {
                ...params
            }, false, false, false, (res: any) => {
                typeof successCb == 'function' && successCb(res);
                resolve(res);
            }, (err: any) => {
                typeof failCb == 'function' && failCb(err)
                reject(err)
            }, showLoading)
        })

    }

    public addMolisDynamic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('addMolisDynamic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public editMolisDynamic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('editMolisDynamic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

    public delMolisDynamic(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls

        const url = apiUrls.get('delMolisDynamic').url

        this.BasicPost(url, {
            ...params
        }, false, false, false, successCb, failCb, showLoading)
    }

  }
